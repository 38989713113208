import {createRoot} from 'react-dom/client';
import {NewsletterSignup} from '~/footer/NewsletterSignup';

const subscribeMessage = 'Party planning tips, gift ideas, special partner offers, and more!';
const container = document.getElementById('newsletter-signup-root');
if (container) {
  const root = createRoot(container);
  root.render(
    <NewsletterSignup
      isSmallButton={!window.is_mobile_site}
      subscribeButtonMessage="Sign up"
      subscribeMessage={subscribeMessage}
    />
  );
}
